import React from 'react'
import githubpng from "../../static/images/devtuplemain.png"
const About = () => (
  <>
    <h1>About</h1>
    <p>We are developers, evangelize technology. Learn from others and help others to learn. We love to share our experiences and eager to listen to others. </p>
    <p>Simplicity is vital for systems and developers' understanding of the details of systems, and we embrace simplicity. </p>
    <p align="center"> 
    <img  src={githubpng} alt="Discord" width='400px' height='300px'/>
    </p>
  </>
)

export default About
